import React, { useEffect, useMemo } from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../../components/layout"
import * as styles from "../../assets/css/projects.module.scss"
import Card from "../../components/card/card"
import Ideas from "../../components/ideas/ideas"
import Seo from "../../components/seo"
import { Strings } from "../../constants/strings"
import { apiProjectTypes } from "../../config/api/endpoints/project_types"
import { apiProjects } from "../../config/api/endpoints/projects"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import Spinner from "../../components/spinner/spinner"
const Projects = () => {
  const [projectTypes, setProjectTypes] = React.useState("initial")
  const [projects, setProjects] = React.useState("initial")
  const [activeType, setActiveType] = React.useState(0)
  const [selectOpen, setSelectOpen] = React.useState(false)
  useEffect(() => {
    apiProjectTypes
      .getAllProjectType()
      .then(res => {
        setProjectTypes(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    setProjects("initial")
    setTimeout(() => {
      apiProjects
        .getAllProjects(activeType)
        .then(res => {
          setProjects(res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    }, 1000)
  }, [activeType])

  let buttons = useMemo(() => {
    if (projectTypes === "initial") return <Spinner />
    return (
      <>
        <button
          className={activeType === 0 ? styles.select_options_selected : ""}
          onClick={() => setActiveType(0)}
        >
          all
        </button>
        {projectTypes.map(type => (
          <button
            className={
              activeType === type.id ? styles.select_options_selected : ""
            }
            onClick={() => setActiveType(type.id)}
            key={type.id}
          >
            {type.attributes.name}
          </button>
        ))}
      </>
    )
  }, [projectTypes, activeType])
//   console.log(projects)
  let cards = useMemo(() => {
    if (projects === "initial") return <Spinner />
    return (
      <div className={styles.grid}>
        {projects.map(project => {
          return (
            <Card
              onClick={() => navigate("/projects/" + project.id)}
              key={project.id}
              data={project}
            />
          )
        })}{" "}
      </div>
    )
  }, [projects])
  return (
    <Layout>
      <Seo title="Projects - Caspian Soft" />
      <h2>{Strings.projects["en"]}</h2>
      <br />
      <br />
      <br />
      <div className={styles.buttons}>
        {buttons}
        <div className={styles.select}>
          <div
            onClick={e => setSelectOpen(prev => !prev)}
            className={styles.select_active_value}
          >
            web development{" "}
            <button>
              {selectOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
          </div>
          {selectOpen && (
            <div className={styles.select_options}>
              {projectTypes.map(type => (
                <div
                  key={type.id}
                  onClick={() => setActiveType(type.id)}
                  className={
                    styles.select_options_option +
                    " " +
                    (activeType === type.id
                      ? styles.select_options_selected
                      : "")
                  }
                >
                  {type.attributes.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
      {cards}
      <br />
      <br />
      <br />
      <Ideas form title={"have a project in mind?"} />
    </Layout>
  )
}

export default Projects
