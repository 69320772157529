import React, { useMemo } from "react"
import { AiOutlineInstagram } from "react-icons/ai"
import Image from "../assets/image"
import * as styles from "./card.module.scss"

const Card = ({ post, data, onClick = () => {} }) => {
  let url = useMemo(() => {
    return data && data.image && data.image.data
      ? data.image.data.attributes.url
      : ""
  }, [data])

  return (
    <div onClick={onClick} className={styles.card}>
      <Image
        src={post ? post.media_url : process.env.GATSBY_STRAPI_API_URL + url}
        alt="card image"
      />
      {data ? (
        <div
          className={
            styles.card_description + " " + styles.card_description_data
          }
        >
          <h3>{data?.attributes?.name}</h3>
          <h4>{data?.attributes?.project_type?.data?.attributes.name}</h4>
        </div>
      ) : (
        <div
          className={
            styles.card_description + " " + styles.card_description_post
          }
        >
          <div className={styles.shadow}>
            <p>caspiansoft</p>
          </div>
          <p>
            {post.caption.length > 40
              ? post.caption.slice(0, 40) + "..."
              : post.caption}
          </p>
          <div className={styles.post_footer}>
            <button>
              <AiOutlineInstagram />
            </button>
            <a href="#">@caspian.soft</a>
          </div>
        </div>
      )}
    </div>
  )
}

export default Card
